import React, { useEffect, useRef } from "react";
import auth from "../auth";
import Version from "../version";
import useWindowDimensions from "../hooks/useWindowDimensions";
import dotnetify, { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import ServerState from '../controls/ServerState';
import SparkleDialog from '../controls/components/SparkleDialog';
import ReactLoading from 'react-loading';
import * as rdd from 'react-device-detect';
import { Paper, Slide, Button, Switch, FormControlLabel } from "@mui/material";
import Mobile from "@mui/icons-material/PhoneIphone";
import LockOutline from "@mui/icons-material/Lock";
import Alert from '@mui/material/Alert';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import { TextField } from '../controls/TextField';
import Logo from '../assets/img/svg/ChilimobilLogo-White.svg';
import bankid from "../assets/img/bankid.png";
import Board from "../assets/img/chalkboard_empty.svg";
import QRCode from 'qrcode.react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

//dotnetify.debug = true;
const BankIdButton = withStyles({
    root: {
        backgroundColor: '#225971',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
        '&:active': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
        '&:visited': {
            backgroundColor: '#225971',
            color: '#FFF',
        },
        '&:focus': {
            backgroundColor: '#469CBE',
            color: '#FFF',
        },
    },
})(Button);

export default function LoginPage(props) {
    const { t } = useTranslation();
    const dialogRef = useRef();
    const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    const getEnc = () => {
        if (window.localStorage.chRememberMe) {
            return window.localStorage.getItem('chPassword');
        } else {
            return '';
        }

    };
    const getUUID = () => {
        var uuid = sessionStorage.getItem("SCDeviceId");
        if (uuid === undefined || uuid === '' || uuid === null) {
            uuid = crypto.randomUUID();
            sessionStorage.setItem("SCDeviceId", uuid);
        };
        return uuid;
    };

    const { vm, state, setState } = useConnect("Login", { ready: false, Init: false, InitLogin: false, StateChanged: '', username: '', password: '', loginStarted: false, dialogDisabled: false, showDialog: false, tabIndex: 0, BankIDStep: 'start', tab1Disabled: false, failureText: null, startMessageText: null, Hidden: false, }, {
        headers: {
            ClientVersion: Version.getVersion(),
        },
        vmArg: {
            InitEnc: getEnc(),
            UUID: getUUID(),
        },
        exceptionHandler: _ => auth.signOut()
    });



    const year = 1900 + new Date().getYear();
    const locale = process.env.REACT_APP_DEFAULTLOCALE;
    var isNorway = false;
    if (locale.toLowerCase() === 'nb-no') {
        isNorway = true;
    }
    var isSweden = !isNorway;
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const ReactGA = window.ReactGA;
    const theme = useTheme();
    const wDim = useWindowDimensions();

    const TimerElapsed = () => {
        if (vm)
            vm.$dispatch({ TimerElapsed: true });
    }
    const UpdateImage = () => {
        if (vm) {
            vm.$dispatch({ UpdateImage: true });
        }
    }
    const validate = () => {
        if (state.username === '') {
            setState({ ErrorText: t('selfcare:error.mobilenumberrequired'), ErrorField: 'username' });
            return false;
        }
        if (!isMobileNumber(state.username)) {
            setState({ ErrorText: t('selfcare:error.mobilenumberinvalid'), ErrorField: 'username' });
            return false;
        }
        if (state.password === '') {
            setState({ ErrorText: t('selfcare:error.passwordrequired'), ErrorField: 'password' });
            return false;
        }
        return true;
    }
    const isMobileNumber = (msisdn) => {
        msisdn = msisdn.replace(/\s+/g, '');
        if (isNorway) {
            if (msisdn.length !== 8 && msisdn.length !== 12)
                return false;
            return true;
        } else {
            if (msisdn.length !== 10 && msisdn.length !== 14)
                return false;
            return true;
        }
    }
    const hasLocalStorage = () => {
        try {
            window.localStorage.setItem("__test", "data");
            window.localStorage.removeItem("__test");
        } catch (e) {
            return false;
        }
        return true;
    }

    const showError = (message) => {
        var notification = {};
        notification.Message = message;
        notification.Variant = 'error';
        setState({ Notification: notification });
    }
    const checkCRMLogin = () => {
        var ticket = getParameterByName("ticket");
        if (!ticket)
            return false;

        const { onAuthenticated } = props;

        setState({ error: null, dialogDisabled: true, loading: true });

        auth.signIn("ticket:" + ticket, 'password')
            .then(_ => {
                setState({ loading: false });
                ReactGA.event({
                    category: 'Navigation',
                    action: 'Login CRM',
                });
                onAuthenticated();
            })
            .catch(error => {
                setState({ showDialog: true });
                showError(error.message);
                setState({ dialogDisabled: false });
                setState({ loading: false });
                setState({ rememberMeChecked: false });
                setState({ rememberMe: false });
            });
        return true;
    }

    const checkAutoLogin = () => {
        if (window.sessionStorage.getItem("loggedOut") === 'TRUE') {
            window.sessionStorage.removeItem("loggedOut");
            return;
        }
        if (hasLocalStorage() && window.localStorage.chRememberMe) {
            setState({ Hidden: true });
            //window.updateAvailable = () => {
            //    window.location.reload(true);
            //};
            const { onAuthenticated } = props;
            //let password = window.localStorage.getItem('chPassword');
            //let user = window.localStorage.getItem('chUsername');
            //vm.$dispatch({
            //    Validate: { username: user, password: password }
            //});
            let { password } = state;
            let user = state.username;

            auth.signIn(user, password)
                .then(_ => {
                    setState({ loading: false });
                    ReactGA.event({
                        category: 'Navigation',
                        action: 'Auto login',
                    });
                    onAuthenticated();
                })
                .catch(error => {
                    setState({ showDialog: true });
                    setState({ Hidden: false });
                    showError(error.message);
                    setState({ dialogDisabled: false });
                    setState({ loading: false });
                    setState({ rememberMeChecked: false });
                    setState({ rememberMe: false });
                });
            return true;
        }
        else {
            return false;
        }
    }

    const handleLogin = e => {
        if (e)
            e.preventDefault();
        const { onAuthenticated } = props;
        let { password } = state;
        let user = state.username;
        vm.$dispatch({
            Validate: { username: user, password: password }
        });
        if (!validate()) {
            //this.shake();
            return;
        }

        setState({ error: null, dialogDisabled: true, loading: true });

        auth.signIn(user, password, null, state.rememberMeChecked)
            .then(_ => {
                if (state.rememberMeChecked && hasLocalStorage() && !window.localStorage.chRememberMe) {
                    window.localStorage.setItem('chRememberMe', true);
                    window.localStorage.setItem('chUsername', user);
                    window.localStorage.setItem('chPassword', '');
                }
                setState({ loading: false });
                ReactGA.event({
                    category: 'Navigation',
                    action: 'Login',
                });
                onAuthenticated();
            })
            .catch(error => {
                showError(error.message);
                //shake();
                setState({ showDialog: true });
                setState({ dialogDisabled: false });
                setState({ loading: false });
                setState({ rememberMeChecked: false });
                setState({ rememberMe: false });
            });
    }
    const init = () => {
        if (hasLocalStorage() && window.localStorage.chRememberMe) {
            setState({ username: window.localStorage.getItem('chUsername'), rememberMe: true, rememberMeChecked: true });
            //try {
            //    await sleepUntil(() => document.querySelector('#ready'), 5000);
            //} catch (err) {
            //    console.log(err);
            //    setState({ rememberMe: false, rememberMeChecked: false });
            //}
            //    setState({ InitLogin: true });
        } else {
            setState({ InitLogin: true });
        }
    }
    const initLogin = () => {
        setState({ loginStarted: true });
        if (checkCRMLogin()) {
            return;
        }
        if (checkAutoLogin()) {
            return;
        }

        setState({ showDialog: true });
        setTimeout(
            function () {
                var el = document.getElementById('username');
                el.focus();
            },
            500
        );
    }
    useEffect(() => {
        sessionStorage.setItem("UpgradedVersion", "Yes");
        const data = 'Client: ' + Version.getVersion();
        window.ReactGA.event({
            category: 'Version',
            action: data
        });
        fetch('/api/security/clientinfo')
            .then(response => response.json())
            .then(res => {
                try {
                    if (res.version !== Version.getVersion()) {
                        window.appInsights.trackTrace({ message: 'New version, current: ' + Version.getVersion() + ' new version: ' + res.version });
                        setState({ Init: true });
                    }
                    else {
                        setState({ Init: true });
                    }
                } catch (err) {
                    console.log(err.message);
                    window.appInsights.trackTrace({ message: "Exception caught: " + err.message });
                }
            });
    }, [])
    useEffect(() => {
        var interval, interval2;

        if (isSweden) {
            interval = setInterval(() => {
                TimerElapsed();
            }, 2000);
            interval2 = setInterval(() => {
                UpdateImage();
            }, 1000);
        }

        return () => {
            if (isSweden) {
                clearInterval(interval);
                clearInterval(interval2);
            }
        }
    }, [vm])
    useEffect(() => {
        if (state?.Init !== undefined && state?.Init !== null && state.Init === true) {
            init();
        }
    }, [state.Init])
    useEffect(() => {
        if (state?.InitLogin !== undefined && state?.InitLogin !== null && state.InitLogin === true) {
            initLogin();
        }
    }, [state.InitLogin])

    useEffect(() => {
        if (state?.ready !== undefined && state?.ready !== null && state.ready === true) {
            if (!state.loginStarted)
                setState({ InitLogin: true });
        }
    }, [state.ready])

    useEffect(() => {
        if (state.Login !== undefined && state.Login !== null) {
            const { onAuthenticated } = props;
            auth.signIn(state.Login.username, state.Login.password)
                .then(_ => {
                    setState({ loading: false });
                    window.ReactGA.event({
                        category: 'Navigation',
                        action: 'BankID login',
                    });
                    onAuthenticated();
                })
                .catch(error => {
                    setState({ Hidden: false });
                    showError(error.message);
                    setState({ dialogDisabled: false });
                    setState({ loading: false });
                    setState({ rememberMeChecked: false });
                    setState({ rememberMe: false });
                });
        }
    }, [state.Login])
    useEffect(() => {
        if (state.BankIDUrlSchema !== undefined && state.BankIDUrlSchema !== null && state.BankIDUrlSchema !== '' && rdd.isIOS && state.BankIDStep === 'local') {
            window.location.assign(state.BankIDUrlSchema);
        }
        if (state.BankIDUrlSchema !== undefined && state.BankIDUrlSchema !== null && state.BankIDUrlSchema !== '' && rdd.isAndroid && state.BankIDStep === 'local') {
            window.location.assign(state.BankIDUrlSchema);
        }
    }, [state.BankIDUrlSchema])
    useEffect(() => {
        if (state.ServerVersion !== undefined && state.ServerVersion !== null && state.ServerVersion !== '') {
            const data = 'Client: ' + Version.getVersion() + " Server: " + state.ServerVersion;
            window.ReactGA.event({
                category: 'Version',
                action: data
            });
        }
    }, [state.ServerVersion])
    const handleChange = name => event => {
        setState({ [name]: event.target.checked });
        if (name === "rememberMeChecked" && !event.target.checked) {
            window.localStorage.removeItem('chRememberMe');
            window.localStorage.removeItem('chUsername');
            window.localStorage.removeItem('chPassword');
            setState({ rememberMe: false, user: '', password: '' });
        }

    }

    const indexChanged = (index) => {
        if (index === state.index)
            return;
        setState({ tabIndex: index });
        switch (index) {
            case 0:
                vm.$dispatch({ CancelBankID: true });
                break;
            case 1:
                break;
        }

    }

    const initBankIDLocal = () => {
        var returnUrl;
        if (rdd.isIOS) {
            if (rdd.isSafari) {
                returnUrl = window.location.protocol + "//" + window.location.host + "/";
            } else if (rdd.isChrome) {
                returnUrl = "googlechromes://";
            } else if (rdd.isFirefox) {
                returnUrl = "firefox:://";
            } else if (rdd.isEdge || rdd.isEdgeChromium) {
                returnUrl = "microsoft-edge-https://";
            } else {
                returnUrl = "null";
            }
        } else {
            returnUrl = "null";
        }
        vm.$dispatch({ InitBankID: { returnUrl: returnUrl, type: 'local' } });
        setState({ BankIDBuzy: true, dialogDisabled: true, tab1Disabled: true, BankIDStep: 'local' });
    }
    const initBankIDOtherDevice = () => {
        var returnUrl;
        if (rdd.isIOS) {
            returnUrl = window.location.protocol + "//" + window.location.host + "/";
        } else {
            returnUrl = "null";
        }
        vm.$dispatch({ InitBankID: { returnUrl: returnUrl, type: 'qr' } });
        setState({ BankIDBuzy: true, dialogDisabled: true, tab1Disabled: true, BankIDStep: 'otherDevice' });
    }

    return (
        <>
            <ServerState serverState={state} setState={setState} />
            <SparkleDialog ref={dialogRef} />
            {isIE ?
                <div style={{ textAlign: 'center', width: '100%', marginTop: '50px' }}>
                    {isNorway ? 'Min Side fungerer dessverre ikke som den skal i Internet Explorer, vennligst bruk en annen nettleser som Google Chrome, Safari, Firefox, Microsoft Edge, Opera, mm.' : 'MittChili fungerar tyvärr inte som den skall i Internet Explorer, vänligen använd en annan browser så som Google Chrome, Safari, Firefox, Microsoft Edge, Opera eller liknande.'}
                </div>
                :
                <>
                    {state.ready &&
                        <div id="ready" />
                    }
                    {!state.Hidden && <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: "center", height: '100vh', paddingTop: '10vh', overflowX: 'hidden', overflowY: 'scroll', WebkitOverflowScrolling: 'touch'
                    }}>
                        <div style={{ maxWidth: '320px', width: '100%' }}>
                            <Slide direction="down" in={state.showDialog} mountOnEnter unmountOnExit>
                                <Paper elevation={5} style={{ padding: '15px', borderRadius: '6px' }}>
                                    <Paper elevation={3} style={{ background: 'linear-gradient(60deg, #E3264E, #fd3c65)', width: '100%', borderRadius: '6px', marginTop: '-40px', padding: '20px 5px 0px' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={Logo} alt='' style={{ width: '70%' }} />
                                            <div style={{ fontSize: '14px', color: '#FFF' }}>
                                                {isNorway ? 'Innlogging for Min Side' : 'Inloggning för MittChili'}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'right', fontSize: '11px', color: '#FFF' }}>
                                            ver. {Version.getVersion()}
                                        </div>
                                    </Paper>
                                    {isSweden &&
                                        <Tabs style={{ marginTop: '20px' }} selectedIndex={state.tabIndex} onSelect={(index) => { indexChanged(index); }}>
                                            <TabList>
                                                <Tab disabled={state.tab1Disabled}>Mobilnummer</Tab>
                                                <Tab>BankID</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <form onSubmit={(e) => handleLogin(e)}>
                                                    <div style={{ padding: '17px 0px' }}>
                                                        <TextField fullWidth
                                                            localState={state}
                                                            name="username"
                                                            setLocalState={setState}
                                                            id="username"
                                                            label={isNorway ? 'Mobilnummer' : 'Mobilnummer'}
                                                            type="tel"
                                                            noPadding
                                                            disabled={state.rememberMe || state.dialogDisabled}
                                                            endIcon={<Mobile style={{ color: '#555', marginRight: '-10px' }} />}
                                                        />
                                                    </div>
                                                    <div style={{ padding: '17px 0px' }}>
                                                        <TextField fullWidth
                                                            localState={state}
                                                            name="password"
                                                            setLocalState={setState}
                                                            id="password"
                                                            label={isNorway ? 'Passord' : 'Lösenord'}
                                                            type="password"
                                                            noPadding
                                                            disabled={state.rememberMe || state.dialogDisabled}
                                                            endIcon={<LockOutline style={{ color: '#555', marginRight: '-10px' }} />}
                                                        />
                                                    </div>
                                                    <div style={{ marginTop: '-20px' }}>
                                                        {hasLocalStorage() ?
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={state.rememberMeChecked}
                                                                        onChange={handleChange('rememberMeChecked')}
                                                                        value="rememberMe"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={isSweden ? 'Fortsätt vara inloggad' : 'Hold meg innlogget'}
                                                                disabled={state.dialogDisabled}
                                                            /> : null}
                                                    </div>
                                                    <div style={{ position: 'relative' }}>
                                                        <LargeButton type="submit" fullWidth disabled={state.dialogDisabled}>
                                                            {t('selfcare:button.login')}
                                                        </LargeButton>
                                                        <div style={{ position: 'absolute', top: 0, left: '45%' }}>
                                                            {state.dialogDisabled && <ReactLoading type={"spinningBubbles"} color={theme.palette.primary.main} height={'38px'} width={'38px'} />}
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <LargeButton variant="outlined" fullWidth disabled={state.dialogDisabled} onClick={(e) => { e.stopPropagation(); dialogRef.current.navigatePage('/new-password', 'glide-left'); }}>
                                                            {t('selfcare:button.register')}
                                                        </LargeButton>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                            <TabPanel style={{ width: '100%' }}>
                                                {state.BankIDStep === 'start' &&
                                                    <div style={{ padding: '0px', textAlign: 'center' }}>
                                                        <img src={bankid} alt="Logga in med BankID" style={{ marginTop: '30px', marginBottom: '80px' }} />
                                                        {!state.failureText &&
                                                            <>
                                                                {rdd.isMobile &&
                                                                    <React.Fragment>
                                                                        <BankIdButton onClick={(event) => { initBankIDLocal(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled} style={{ marginBottom: '10px' }}>
                                                                            BankID på denna enhet
                                                                        </BankIdButton>
                                                                        <BankIdButton onClick={(event) => { initBankIDOtherDevice(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                                            BankID på annan enhet
                                                                        </BankIdButton>
                                                                    </React.Fragment>
                                                                }
                                                                {!rdd.isMobile &&
                                                                    <>
                                                                        <BankIdButton onClick={(event) => { initBankIDOtherDevice(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled} style={{ marginBottom: '10px' }}>
                                                                            Mobilt BankID
                                                                        </BankIdButton>
                                                                        <BankIdButton onClick={(event) => { initBankIDLocal(); }} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                                            BankID på denna enhet
                                                                        </BankIdButton>
                                                                    </>
                                                                }
                                                            </>}
                                                        {state.failureText &&
                                                            <React.Fragment>
                                                                <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                                <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null }); }}>
                                                                    Försök igen
                                                                </Button>
                                                            </React.Fragment>}
                                                    </div>}
                                                {state.BankIDStep === "otherDevice" &&
                                                    <div style={{ padding: '0px', textAlign: 'center' }}>
                                                        {state.startMessageText && !state.failureText &&
                                                            <div style={{ marginBottom: '10px' }}>
                                                                {state.startMessageText}
                                                            </div>}
                                                        {state.failureText &&
                                                            <>
                                                                <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                                <BankIdButton style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                                    Försök igen
                                                                </BankIdButton>
                                                            </>}
                                                        {(state.BankIDBuzy || state.startMessageText) && !state.failureText &&
                                                            <div style={{ width: '100%', paddingBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                                            </div>}
                                                        {!state.startMessageText && state.QRValue &&
                                                            <>
                                                                <div style={{ marginBottom: '10px', width: '100%' }}>
                                                                    Starta din BankID-app
                                                                </div>
                                                                <QRCode value={state.QRValue} size={200} />
                                                                <div style={{ fontSize: '12px', marginBottom: '20px', marginTop: '10px' }}>
                                                                    Starta BankID-appen på din mobil eller surfplatta.<br />
                                                                    Klicka på QR-ikonen i appen och läs av QR-koden ovan.
                                                                </div>
                                                            </>}
                                                        {!state.failureText && <Button variant="outlined" color="primary" size="large" fullWidth disabled={state.dialogDisabled} onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                            Avbryt
                                                        </Button>}

                                                    </div>}
                                                {state.BankIDStep === "local" &&
                                                    <>
                                                        {!rdd.isMobile && state.BankIDUrlSchema &&
                                                            <iframe src={state.BankIDUrlSchema} width={0} height={0} frameBorder={0} />}
                                                        <div style={{ padding: '0px', textAlign: 'center' }}>
                                                            {state.startMessageText && !state.failureText &&
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    {state.startMessageText}
                                                                </div>}
                                                            {state.failureText &&
                                                                <>
                                                                    <Alert severity="error"><span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: state.failureText }} /></Alert>
                                                                    <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '', failureText: null, startMessageText: null }); }}>
                                                                        Försök igen
                                                                    </Button>
                                                                </>}
                                                            {!state.failureText && state.startMessageText &&
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                                                </div>}

                                                            {!state.failureText && !state.startMessageText &&
                                                                <React.Fragment>
                                                                    <div>
                                                                        Startar BankID appen
                                                                    </div>
                                                                    <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <ReactLoading type={"spin"} color='#469CBE' height={'36px'} width={'36px'} />
                                                                    </div>
                                                                    <BankIdButton href={state.BankIDUrlSchema} variant="contained" color="primary" size="large" fullWidth disabled={state.dialogDisabled}>
                                                                        Öppna BankID
                                                                    </BankIdButton>
                                                                    <Button style={{ marginTop: '10px' }} variant="outlined" color="primary" size="large" fullWidth onClick={(event) => { vm.$dispatch({ CancelBankID: true }); setState({ BankIDStep: 'start', tab1Disabled: false, QRValue: '' }); }}>
                                                                        Avbryt inloggning
                                                                    </Button>
                                                                </React.Fragment>}
                                                        </div>
                                                    </>}
                                            </TabPanel>
                                        </Tabs>
                                    }
                                    {isNorway &&
                                        <form onSubmit={(e) => handleLogin(e)}>
                                            <div style={{ padding: '34px 0px 0px' }}>
                                                <TextField
                                                    autoComplete="username"
                                                    fullWidth
                                                    localState={state}
                                                    name="username"
                                                    setLocalState={setState}
                                                    id="username"
                                                    label={isNorway ? 'Mobilnummer' : 'Mobilnummer'}
                                                    type="tel"
                                                    noPadding
                                                    disabled={state.rememberMe || state.dialogDisabled}
                                                    endIcon={<Mobile style={{ color: '#555', marginRight: '-10px' }} />}
                                                />
                                            </div>
                                            <div style={{ padding: '34px 0px' }}>
                                                <TextField
                                                    autoComplete="current-password"
                                                    fullWidth
                                                    localState={state}
                                                    name="password"
                                                    setLocalState={setState}
                                                    id="password"
                                                    label={isNorway ? 'Passord' : 'Lösenord'}
                                                    type="password"
                                                    noPadding
                                                    disabled={state.rememberMe || state.dialogDisabled}
                                                    endIcon={<LockOutline style={{ color: '#555', marginRight: '-10px' }} />}
                                                />
                                            </div>
                                            <div style={{ marginTop: '-20px' }}>
                                                {hasLocalStorage() ?
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={state.rememberMeChecked}
                                                                onChange={handleChange('rememberMeChecked')}
                                                                value="rememberMe"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={isSweden ? 'Fortsätt vara inloggad' : 'Hold meg innlogget'}
                                                        disabled={state.dialogDisabled}
                                                    /> : null}
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <LargeButton type="submit" fullWidth disabled={state.dialogDisabled}>
                                                    {t('selfcare:button.login')}
                                                </LargeButton>
                                                <div style={{ position: 'absolute', top: 0, left: '45%' }}>
                                                    {state.dialogDisabled && <ReactLoading type={"spinningBubbles"} color={theme.palette.primary.main} height={'38px'} width={'38px'} />}
                                                </div>
                                            </div>
                                            <div style={{ paddingTop: '10px' }}>
                                                <LargeButton variant="outlined" fullWidth disabled={state.dialogDisabled} onClick={(e) => { e.stopPropagation(); dialogRef.current.navigatePage('/new-password', 'glide-left'); }}>
                                                    {t('selfcare:button.register')}
                                                </LargeButton>
                                            </div>
                                        </form>
                                    }
                                </Paper>
                            </Slide>
                        </div>
                        <div style={{ maxWidth: '330px', position: 'relative', top: '-440px', padding: '10px', display: state.SelfcareDisabled ? 'block' : 'none', zIndex: '999' }}>
                            <img src={Board} style={{ maxWidth: '320px', marginLeft: '-4px' }} alt="" />
                            <div style={{ position: 'relative', top: '-255px', left: '25px', width: '270px', height: '180px', color: 'white', fontStyle: 'Italic', marginLeft: '-5px' }}>
                                <div style={{ fontSize: '24px', fontWeight: 700 }} dangerouslySetInnerHTML={{ __html: state.SelfcareDisabledTitle }} />
                                <div dangerouslySetInnerHTML={{ __html: state.SelfcareDisabledMessage }} />
                            </div>
                        </div>
                        <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                            © {year}{isNorway ? ' Chilimobil AS' : ' Chilimobil Sweden AB'}
                        </div>
                    </div>}
                </>
            }
        </>
    );
}